<template>
  <v-footer id="AppFooter"  v-bind="Entorno">
    <v-container>
      <v-row align="center" no-gutters>
        <v-col>
          <div style="text-align: center">
            2020 ABAKAN sistemas de informacion, SL
          </div>
          <div>{{ Entorno.color }}</div>
        </v-col>
        <!-- <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <a
            :href="link.href"
            class="mr-0 grey--text text--darken-3"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2019, made with
            <v-icon size="18">
              mdi-heart
            </v-icon>
            by <a href="https://www.creative-tim.com">Creative Tim</a> for a better web.
          </div>
        </v-col>-->
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      Entorno: this.$cfg.app.footer,
      Mdrawer: true
    };
  }
};
</script>
